import { ComponentPropsWithoutRef } from 'react'
import { cn } from 'ui/lib'
import { Button } from 'ui/src/components/forms'

export function InfoCardOffPisteInvestmentsMobile({
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={cn(
        'bg-deep-gray-50 relative flex flex-col gap-4 rounded-lg px-4 py-6 shadow',
        className,
      )}
      {...props}
    >
      <p className="text-deep-teal-500 font-brand text-base font-medium leading-5 ">
        Deals in Off-Piste Stage have been submitted by members directly & not yet reviewed by mp
      </p>
      <div className="flex gap-3">
        <Button
          uiType="primary"
          size="xs"
          href="https://meetperryforms.fillout.com/t/3ZFRU4Eq4sus"
          target="_blank"
        >
          Submit a Deal
        </Button>
        <Button uiType="secondary" size="xs" href="/articles/off-piste-faq">
          Read FAQ
        </Button>
      </div>
      <BackgroundImage className="absolute bottom-[-18px] right-0" />
    </div>
  )
}

function BackgroundImage(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="91"
      height="72"
      viewBox="0 0 91 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23.1422 19.043L43.184 53.7563H3.10047L23.1422 19.043Z" fill="#CCC9C9" />
      <path d="M55.6983 1L86.2705 53.9526H25.1261L55.6983 1Z" fill="#D9D9D9" />
      <path
        d="M21.8871 27.3725C20.5937 26.0791 16.2166 30.9572 16.486 30.4183L23.2344 19.4355L29.0378 29.6338C28.4989 30.2625 25.8211 32.546 23.2344 34.9172C20.0009 37.8812 23.5038 28.9892 21.8871 27.3725Z"
        fill="white"
        stroke="white"
        strokeWidth="0.538907"
      />
      <path
        d="M54.9258 11.9828C53.0431 10.1 46.6888 17.4741 47.081 16.6897L55.7103 1L65.9086 19.0431C65.1241 19.9583 61.8293 24.2207 58.0638 27.6724C53.3569 31.9871 57.2793 14.3362 54.9258 11.9828Z"
        fill="white"
        stroke="white"
        strokeWidth="0.784483"
      />
    </svg>
  )
}

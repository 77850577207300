import { ComponentPropsWithoutRef } from 'react'
import { cn } from 'ui/lib'
import { Button } from 'ui/src/components/forms'

export function InfoCardPipelineInvestmentsMobile({
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={cn(
        'bg-deep-gray-50 relative flex flex-col gap-4 overflow-hidden rounded-lg px-4 py-6 shadow',
        className,
      )}
      {...props}
    >
      <p className="text-deep-teal-500 font-brand text-base font-medium leading-5 ">
        Pipeline Stage includes deals actively under consideration by our team & the Field Experts.
      </p>
      <div className="flex gap-3">
        <Button
          uiType="primary"
          size="xs"
          href="https://meetperryforms.fillout.com/t/3ZFRU4Eq4sus"
          target="_blank"
        >
          Submit a Deal
        </Button>
        <Button uiType="secondary" size="xs" href="/articles/pipeline-faq">
          Read FAQ
        </Button>
      </div>
      <BackgroundImage className="absolute bottom-0 right-0" />
    </div>
  )
}

function BackgroundImage(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="90"
      height="50"
      viewBox="0 0 90 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 26H22V50H0V26Z" fill="#D9D9D9" />
      <path
        d="M90 4L90 26L-9.61651e-07 26L-4.37114e-07 14C-1.95703e-07 8.47715 4.47715 4 10 4L90 4Z"
        fill="#D9D9D9"
      />
      <rect x="32" width="20" height="30" rx="1" fill="#CCC9C9" />
    </svg>
  )
}

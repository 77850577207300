import { ComponentPropsWithoutRef } from 'react'
import { Link } from 'ui/components/navigation'
import { cn } from 'ui/lib'

export function InfoCardPipelineInvestmentsDesktop({
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) {
  return (
    <Link
      className={cn(
        'bg-deep-gray-50 flex flex-col gap-2 rounded-lg p-4 shadow transition-shadow hover:shadow-xl',
        className,
      )}
      href="/articles/pipeline-faq"
      target="_blank"
      {...props}
    >
      <div className="flex h-full w-full items-end justify-center overflow-hidden">
        <TitleImage />
      </div>
      <p className="font-brand text-deep-teal-500 text-2xl font-medium">Read our Pipeline FAQ</p>
      <p className="text-deep-teal-400 text-xs font-normal">
        The Pipeline deals and deal themes are currently in early stages of diligence by our team
        and the Field Experts. Submit an IOI to help us gauge demand for these opportunities.
      </p>
    </Link>
  )
}

function TitleImage(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="289"
      height="299"
      viewBox="0 0 289 299"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="30.4663"
        y="63.1655"
        width="43.3931"
        height="272.635"
        transform="rotate(-42.2309 30.4663 63.1655)"
        fill="#E0E1E3"
      />
      <rect
        x="0.147186"
        y="64.1519"
        width="95.4473"
        height="31.5275"
        rx="3.05458"
        transform="rotate(-42.2309 0.147186 64.1519)"
        fill="#E0E1E3"
      />
      <rect
        x="196.681"
        y="275.656"
        width="95.4473"
        height="31.5275"
        rx="3.05458"
        transform="rotate(-42.2309 196.681 275.656)"
        fill="#E0E1E3"
      />
      <circle cx="137.499" cy="149.267" r="80.5645" stroke="#E0E1E3" stroke-width="24" />
      <path
        d="M140.799 202.095C169.553 200.694 191.728 176.248 190.327 147.494C188.926 118.74 164.481 96.5654 135.726 97.9661C106.972 99.3669 84.7974 123.812 86.1982 152.567C87.5989 181.321 112.044 203.496 140.799 202.095Z"
        fill="#0A373C"
      />
      <path
        d="M135.949 137.962C139.998 137.765 142.33 140.079 142.546 144.505L143.274 159.45L140.56 159.583L139.86 145.221C139.73 142.549 138.988 140.2 135.481 140.371C131.724 140.554 129.652 143.542 129.802 146.632L130.457 160.075L127.743 160.207L127.044 145.845C126.913 143.174 126.17 140.825 122.622 140.998C118.907 141.179 116.792 144.168 116.943 147.258L117.598 160.701L114.884 160.833L113.847 139.541L116.56 139.409L116.707 142.414L116.79 142.41C117.835 140.644 119.75 138.751 123.091 138.588C126.097 138.442 128.084 139.726 129.002 142.234L129.085 142.23C130.36 140.034 132.568 138.127 135.949 137.962ZM157.566 136.784C162.993 136.519 167.334 140.577 167.675 147.591C168.017 154.605 164.089 159.023 158.662 159.287C155.322 159.45 153.202 158.047 151.95 156.391L151.867 156.395L152.355 166.415L148.263 166.614L146.866 137.933L150.958 137.733L151.08 140.239L151.163 140.235C152.259 138.675 154.226 136.946 157.566 136.784ZM157.829 155.937C161.837 155.742 163.579 151.975 163.375 147.8C163.172 143.625 161.072 140.045 157.064 140.24C153.39 140.419 151.281 143.534 151.517 148.378C151.751 153.178 154.155 156.116 157.829 155.937Z"
        fill="white"
      />
    </svg>
  )
}
